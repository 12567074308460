<template>
  <div class="contact">
    <div class="contact-content">
      <div class="contact-content-title">联系我们</div>
      <el-form ref="ruleForm" class="demo-ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item required>
          <el-col :span="11">
            <el-form-item prop="username">
              <el-input v-model="ruleForm.username" placeholder="联系人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">
            <div class="opacity"></div>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="phone">
              <el-input v-model="ruleForm.phone" placeholder="手机号码" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item required>
          <el-col :span="11">
            <el-form-item prop="firmname">
              <el-input v-model="ruleForm.firmname" placeholder="企业名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2">
            <div class="opacity"></div>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="posts">
              <el-input v-model="ruleForm.posts" placeholder="职位"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="ruleForm.email" placeholder="请输入您的邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="des">
          <el-input v-model="ruleForm.des" placeholder="请写明您的具体需求，以便我们安排相应顾问跟您联系（选填）" rows="4"
            type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" style="width: 180px;height: 50px;" type="primary"
            @click="submit('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  name: "Contact",
  data () {
    return {
      ruleForm: {
        username: '',
        phone: '',
        firmname: '',
        posts: '',
        email: '',
        des: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入号码', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
        firmname: [
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        posts: [
          { required: true, message: '请填写职位', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请填写邮箱', trigger: 'blur' }
        ]
      },
    }
  },
  mounted () {
  },
  methods: {
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$alert('我们会尽快联系您', '提交成功', {
            confirmButtonText: '好的',
            callback: action => {
            }
          });
        } else {
          // return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.contact {
  box-sizing: border-box;
  background-color: #fff;
  padding: 1.5vw 4vw;
  margin: 1.5vw 0 4vw;
  display: flex;
  justify-content: center;

  &-content {
    text-align: center;
    width: 80%;

    &-title {
      margin-bottom: 40px;
      font-size: 24px;
      font-weight: 500;
    }

    .opacity {
      width: 100%;
      height: 10px;
    }
  }
}
</style>